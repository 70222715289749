import '../css/app.scss';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require('bootstrap');

const toastr = require('toastr');
window.toastr = toastr;
const chosen = require('chosen-js/chosen.jquery.js');
import '@awesome.me/kit-4f379cd2be';

$(document).ready(function(){
    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-bottom-left",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    if (msg.notice !== undefined) {
        msg.notice.forEach(function(element) {
            toastr.info(element);
        });
    }

    if (msg.warning !== undefined) {
        msg.warning.forEach(function(element) {
            toastr.warning(element);
        });
    }

    if (msg.error !== undefined) {
        msg.error.forEach(function(element) {
            toastr.error(element);
        });
    }

    if (msg.success !== undefined) {
        msg.success.forEach(function(element) {
            toastr.success(element);
        });
    }

    $(".chosen-select").chosen({
        no_results_text: "Pas de résultats !",
        default_multiple_text: "Selectionner des options"
    });
});

$(document).on('change', '.custom-file-input', function () {
    let fileName = $(this).val().replace(/\\/g, '/').replace(/.*\//, '');
    $(this).parent('.custom-file').find('.custom-file-label').text(fileName);
});